@charset "UTF-8";
body {
  --mainBackground: #F2F1F1;
  --app-page-background: #FFFFFF;
  --text: #3C4776;
  --importText: #4F6DEF;
  --colorButton: #FFFFFF;
  --backgroundButtonIcon: #FFFFFF;
  --inputBackground: #DFE3EE;
  --inputReadonlyBackground: #FFFFFF;
  --inputReadonlyBorder: #DFE3EE;
  --selectBackground: #DFE3EE;
  --tableRowHover: #DFE3EE;
  --tabsNavText: #4F6DEF;
  --tabsNavTextActive: #3C4776;
  --labelCheckbox: #3C4776;
  --venteImmoStep: #4F6DEF;
  --tableCellNumber: #DFE3EE;
  --tableCellNumberText: #3C4776;
  --tableCellNumberTextHover: #3C4776;
  --color3: #DFE3EE;
  --color10: #000000;
}

body.dark-mode {
  --mainBackground: #1A143E;
  --app-page-background: #413875;
  --text: #FFFFFF;
  --colorButton: #413875;
  --backgroundButtonIcon: #413875;
  --inputBackground: #908DB2;
  --inputReadonlyBackground: #E5E5E5;
  --inputReadonlyBorder: #908DB2;
  --selectBackground: #908DB2;
  --tableRowHover: #908DB2;
  --tabsNavText: #FFFFFF;
  --tabsNavTextActive: #FFFFFF;
  --labelCheckbox: #FFFFFF;
  --venteImmoStep: #FFFFFF;
  --tableCellNumber: #908DB2;
  --tableCellNumberText: #3C4776;
  --tableCellNumberTextHover: #3C4776;
  --color3: #908DB2;
  --color10: #6573FF;
}

.c-danger {
  color: #F65858;
}

.c-green {
  color: #16E1AC;
}

.c-warning {
  color: #FFA800;
}

*, *:before, *:after {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -ms-font-smoothing: antialiased;
  font-smoothing: antialiased;
}

body {
  background: var(--mainBackground);
  margin: 0;
  padding: 0;
  font-family: Roboto;
  font-size: 14px;
  color: var(--text);
  height: 100vh;
}

.app-link * {
  pointer-events: none;
}

.hide {
  display: none;
}

a {
  text-decoration: none;
  color: inherit;
}

.margin-top {
  margin-top: 30px;
}

.tooltip {
  cursor: pointer;
  font-weight: bold;
}
.tooltip.tooltip-info {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: none;
  width: 22px;
  height: 22px;
  padding: 2px;
  border-radius: 50%;
  background-color: #4F6DEF;
}
.tooltip.tooltip-info svg {
  width: 100%;
  height: 100%;
  fill: #FFFFFF;
}

.tooltip-content {
  display: none;
}

.form-line {
  margin-bottom: 20px;
}

.form-bloc-group {
  align-items: center;
  gap: 20px;
}

.form-bloc {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
  margin-right: 20px;
  width: calc(25% - 15px);
}
.form-bloc-group .form-bloc {
  margin-bottom: 0;
  margin-right: 0;
}
.form-bloc:nth-of-type(4n) {
  margin-right: 0px;
}
@media screen and (max-width: 1439px) {
  .form-bloc {
    width: calc(50% - 10px);
  }
  .form-bloc:nth-of-type(even) {
    margin-right: 0px;
  }
}
@media screen and (max-width: 1439px) and (max-width: 767px) {
  .form-bloc {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }
}
.form-bloc.hide {
  display: none;
}
@media screen and (min-width: 1440px) {
  .form-bloc.half {
    width: calc(50% - 10px);
  }
  .form-bloc.half:nth-of-type(even) {
    margin-right: 0px;
  }
}
.form-bloc.clear {
  margin-right: calc(75% - 15px);
}
.form-section-5 .form-bloc {
  width: calc(20% - 20px);
}
.form-section-5 .form-bloc:nth-of-type(4n) {
  margin-right: 20px;
}
.form-section-5 .form-bloc:nth-of-type(5n) {
  margin-right: 0px;
}
@media screen and (max-width: 1439px) {
  .form-section-5 .form-bloc {
    width: calc(50% - 10px);
  }
  .form-section-5 .form-bloc:nth-of-type(even) {
    margin-right: 0px;
  }
  .form-section-5 .form-bloc:nth-of-type(5n) {
    margin-right: 20px;
  }
}
.form-section-5 .form-bloc.clear {
  margin-right: calc(80% - 20px);
}
.form-bloc.full {
  width: 100%;
  margin-right: 0;
}
.form-bloc label:not(.sub-label) {
  display: block;
  width: 100%;
  margin-bottom: 8px;
}
.form-bloc label.flex {
  display: flex;
  align-items: center;
  gap: 5px;
}
.form-bloc label.sub-label {
  display: flex;
  align-items: center;
  margin: 0 20px;
}
.form-bloc label.no-margin {
  margin: 0;
}
.form-bloc .inner-small {
  display: block;
  font-size: 10px;
}
.form-bloc.form-bloc-phone .select-container {
  width: 150px;
  margin-right: 5px;
}
.form-bloc.form-bloc-phone input[type=tel] {
  flex: 1;
}
.form-bloc.no-margin-bottom {
  margin-bottom: 0;
}

form .bottom {
  flex: none;
  display: flex;
  width: 100%;
  padding: 30px 0 13px;
}
form .bottom:not(.no-border) {
  border-top: 1px solid #BBCCFF;
}
form .bottom button {
  width: 165px;
}
@media screen and (max-width: 767px) {
  form .bottom {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  form .bottom button {
    margin-left: auto;
    margin-top: 20px;
    justify-self: flex-end;
  }
}

.form-section {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  width: 100%;
}
.form-section.hide {
  display: none;
}

.info-text {
  width: 100%;
  margin-bottom: 30px;
}
.info-text.small {
  font-size: 12px;
}

.input-line {
  position: relative;
}
.input-line input[type=email],
.input-line input[type=password],
.input-line input[type=number],
.input-line input[type=text] {
  padding-right: 73px;
}
.input-line input[type=email] + .icon,
.input-line input[type=password] + .icon,
.input-line input[type=number] + .icon,
.input-line input[type=text] + .icon {
  width: 12px;
  height: 12px;
  position: absolute;
  right: 42px;
  top: 50%;
  margin-top: -6px;
  fill: #7277A9;
  opacity: 0.3;
}
.input-line input[type=email]:focus + .icon,
.input-line input[type=password]:focus + .icon,
.input-line input[type=number]:focus + .icon,
.input-line input[type=text]:focus + .icon {
  opacity: 1;
}

.form-message.error {
  color: #e74c3c;
}

label {
  font-size: 13px;
  font-weight: bold;
  color: #BBCCFF;
}

label.sub-label {
  color: #4F6DEF;
}

form .bottom {
  display: flex;
  background: var(--app-page-background);
  padding-bottom: 30px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.form-bloc-item {
  width: 100%;
  display: flex;
  align-items: center;
}
.form-bloc-item.half {
  width: 50%;
}
.form-bloc-item .radio-style + label {
  flex-wrap: nowrap;
}
.form-bloc-item.column {
  flex-direction: column;
  align-items: flex-start;
}

textarea,
input[type=date],
input[type=datetime-local],
input[type=tel],
input[type=email],
input[type=password],
input[type=number],
input[type=text]:not(.choices__input) {
  flex: 1;
  width: 100%;
  height: 40px;
  padding: 12px 19px;
  border-radius: 28px;
  border: none;
  outline: none;
  background-color: var(--inputBackground);
  color: #3C4776;
  font-family: Roboto;
  font-size: 16px;
  border: 1px solid transparent;
  transition: border-color 0.3s, background 0.3s;
}
textarea.error,
input[type=date].error,
input[type=datetime-local].error,
input[type=tel].error,
input[type=email].error,
input[type=password].error,
input[type=number].error,
input[type=text]:not(.choices__input).error {
  border-color: #F65858;
}
textarea:read-only:not(.important),
input[type=date]:read-only:not(.important),
input[type=datetime-local]:read-only:not(.important),
input[type=tel]:read-only:not(.important),
input[type=email]:read-only:not(.important),
input[type=password]:read-only:not(.important),
input[type=number]:read-only:not(.important),
input[type=text]:not(.choices__input):read-only:not(.important) {
  border-color: var(--inputReadonlyBorder);
  background-color: var(--inputReadonlyBackground);
}
textarea.important,
input[type=date].important,
input[type=datetime-local].important,
input[type=tel].important,
input[type=email].important,
input[type=password].important,
input[type=number].important,
input[type=text]:not(.choices__input).important {
  background-color: #4F6DEF;
  color: #FFFFFF;
}

textarea {
  height: 100px;
  resize: none;
}

input[type=date],
input[type=datetime-local] {
  padding-right: 10px;
  position: relative;
}
input[type=date]::after, input[type=date]::before,
input[type=datetime-local]::after,
input[type=datetime-local]::before {
  content: "";
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background-color: #4F6DEF;
  position: absolute;
  right: 10px;
  pointer-events: none;
}
input[type=date]::after,
input[type=datetime-local]::after {
  content: "";
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background-color: #FFFFFF;
  -webkit-mask-image: url("../svg/calendar.svg");
  mask-image: url("../svg/calendar.svg");
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-size: 14px;
  mask-size: 14px;
  -webkit-mask-position: center;
  mask-position: center;
  pointer-events: none;
}

.input-append {
  position: relative;
  display: block;
  height: 40px;
  width: 100%;
  border-radius: 20px;
  overflow: hidden;
}
.input-append.hide {
  display: none;
}
.input-append::after {
  content: attr(data-append);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 22px;
  height: 22px;
  border-radius: 11px;
  position: absolute;
  right: 10px;
  top: 50%;
  margin-top: -11px;
  font-weight: 500;
  color: #ffffff;
  background-color: #4F6DEF;
}
.input-append input[type=number] {
  padding-right: 40px;
}
.input-append[data-append="an(s)"]::after {
  width: 45px;
}
.input-append[data-append="an(s)"] input[type=number] {
  padding-right: 63px;
}
.input-append[data-append="€/mois"]::after {
  width: 50px;
  font-size: 12px;
}
.input-append[data-append="€/mois"] input[type=number] {
  padding-right: 67px;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #7277A9;
  opacity: 1;
  /* Firefox */
  font-style: italic;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #7277A9;
  font-style: italic;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #7277A9;
  font-style: italic;
}

input[type=checkbox] {
  display: none;
}
input[type=checkbox] + label {
  position: relative;
  display: inline-flex !important;
  align-items: center;
  color: var(--labelCheckbox);
  font-weight: normal;
  font-size: 16px;
  cursor: pointer;
}
input[type=checkbox] + label::before {
  content: "";
  display: inline-block;
  width: 32px;
  height: 32px;
  border-width: 5px;
  border-style: solid;
  border-color: #E9EEFF;
  border-radius: 6px;
  margin-right: 25px;
  flex: none;
}
input[type=checkbox] + label::after {
  content: "";
  display: inline-block;
  width: 12px;
  height: 10px;
  position: absolute;
  left: 10px;
  opacity: 0;
  transform: scale(0);
  transition: all 0.3s;
  background-color: #4F6DEF;
  -webkit-mask-image: url("../svg/check.svg");
  mask-image: url("../svg/check.svg");
  -webkit-mask-size: cover;
  mask-size: cover;
}
input[type=checkbox]:checked + label::after {
  opacity: 1;
  transform: scale(1);
  transition: all 0.3s cubic-bezier(0, 1.81, 0.74, 0.99);
}

input[type=radio] {
  display: none;
}
input[type=radio] + label {
  position: relative;
  display: inline-flex !important;
  flex-wrap: wrap;
  align-items: center;
  color: var(--labelCheckbox);
  font-weight: normal;
  font-size: 16px;
  cursor: pointer;
  margin-right: 30px;
}
input[type=radio] + label:last-of-type {
  margin-right: 0;
}
input[type=radio] + label > span:not(.small) {
  flex: 1;
}
input[type=radio] + label .small {
  font-size: 12px;
  display: block;
}
input[type=radio] + label::before {
  content: "";
  display: inline-block;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border-width: 5px;
  border-style: solid;
  border-color: #E9EEFF;
  margin-right: 25px;
  flex: none;
}
input[type=radio] + label::after {
  content: "";
  flex: none;
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  position: absolute;
  left: 10px;
  opacity: 0;
  transform: scale(0);
  transition: all 0.3s;
  background-color: #4F6DEF;
}
input[type=radio]:checked + label::after {
  opacity: 1;
  transform: scale(1);
  transition: all 0.3s cubic-bezier(0, 1.81, 0.74, 0.99);
}

input[type=file] {
  display: none;
}
input[type=file] + label {
  display: flex !important;
  width: auto !important;
  align-items: center;
  margin-right: 20px;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 68px;
  height: 26px;
}
.switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 13px;
  background-color: #FFFFFF;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.switch .slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  border-radius: 50%;
  left: 3px;
  bottom: 3px;
  background-color: #F65858;
  transition: 0.4s;
  z-index: 1;
}
.switch .slider .switch-text {
  display: flex;
  align-items: center;
  position: absolute;
  height: 26px;
  width: 35px;
  opacity: 0;
  transition: opacity 0.4s;
}
.switch .slider .switch-text.off {
  right: 3px;
  opacity: 1;
  color: #F65858;
}
.switch .slider .switch-text.on {
  left: 3px;
  color: #16E1AC;
  padding-left: 13px;
}
.switch input:checked + .slider:before {
  background-color: #16E1AC;
  transform: translateX(42px);
}
.switch input:checked + .slider .switch-text.off {
  opacity: 0;
}
.switch input:checked + .slider .switch-text.on {
  opacity: 1;
}

a.button {
  display: inline-flex;
  text-decoration: none;
  align-items: center;
}

.button,
button {
  display: inline-flex;
  align-items: center;
  height: 42px;
  border-radius: 23px;
  border: none;
  outline: none;
  cursor: pointer;
  color: var(--colorButton);
  font-size: 14px;
  font-weight: bold;
  font-family: Roboto;
  padding: 0 10px;
  background: transparent;
}
.button *,
button * {
  pointer-events: none;
}
.button.hide,
button.hide {
  display: none;
}
.button.margin-h,
button.margin-h {
  margin: 0 20px;
}
.button.margin-v,
button.margin-v {
  margin: 20px 0;
}
.button:disabled,
button:disabled {
  cursor: default;
  opacity: 0.5;
}
.button.icon-round span.icon,
button.icon-round span.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  padding: 6px;
  background-color: var(--backgroundButtonIcon);
  flex: none;
}
.button.icon-round span.icon svg,
button.icon-round span.icon svg {
  width: 100%;
  height: 100%;
}
.button.icon-round span.icon.icon-view,
button.icon-round span.icon.icon-view {
  padding: 4px;
}
.button.icon-round span.icon + span,
button.icon-round span.icon + span {
  margin-left: 10px;
}
@media screen and (max-width: 767px) {
  .button.icon-round.only-icon-xs,
button.icon-round.only-icon-xs {
    width: 42px;
    align-items: center;
    justify-content: center;
    padding: 0;
  }
  .button.icon-round.only-icon-xs span:not(.icon),
button.icon-round.only-icon-xs span:not(.icon) {
    display: none;
  }
}
.button.border,
button.border {
  background-color: #FFFFFF;
  border: 2px solid transparent;
}
.button.icon-only,
button.icon-only {
  width: 42px;
  transform: scale(1);
  transition: transform 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
}
.button.icon-only .icon,
button.icon-only .icon {
  width: 18px;
  height: 18px;
}
.button.icon-only:hover,
button.icon-only:hover {
  transform: scale(1.2);
}
.button.default,
button.default {
  background: #6573FF;
  fill: #6573FF;
}
.button.default.icon-only span.icon,
button.default.icon-only span.icon {
  background-color: #6573FF;
  fill: #FFFFFF;
}
.button.shadow,
button.shadow {
  box-shadow: 0px 0px 20px rgba(79, 109, 239, 0.3);
}
.button.danger:not(.border),
button.danger:not(.border) {
  background: #F65858;
  fill: #F65858;
}
.button.danger.border,
button.danger.border {
  border-color: #F65858;
  color: #F65858;
  fill: #FFFFFF;
}
.button.danger.border .icon,
button.danger.border .icon {
  background-color: #F65858;
}
.button.delete,
button.delete {
  color: #F65858;
  fill: #F65858;
}
.button.add:not(.border),
button.add:not(.border) {
  background: #4F6DEF;
}
.button.add.border,
button.add.border {
  border-color: #4F6DEF;
}
.button.add .icon,
button.add .icon {
  fill: #4F6DEF;
}
.button.save.border.enable, .button.save:not(.border),
button.save.border.enable,
button.save:not(.border) {
  background: #16E1AC;
  fill: #16E1AC;
}
.button.save.border,
button.save.border {
  border-color: #16E1AC;
  color: #16E1AC;
  fill: #FFFFFF;
}
.button.save.border .icon,
button.save.border .icon {
  background-color: #16E1AC;
}
.button.save.border.enable,
button.save.border.enable {
  color: #FFFFFF;
}
.button.save.border.enable .icon,
button.save.border.enable .icon {
  background-color: #FFFFFF;
}
.button.warning:not(.border),
button.warning:not(.border) {
  background: #FFA800;
  fill: #FFA800;
}
.button.warning.border,
button.warning.border {
  border-color: #FFA800;
  color: #FFA800;
  fill: #FFFFFF;
}
.button.warning.border .icon,
button.warning.border .icon {
  background-color: #FFA800;
}
.button.search,
button.search {
  background: #FFA800;
}
.button.search .icon,
button.search .icon {
  fill: #FFA800;
}
.button.order .icon,
button.order .icon {
  width: 8px;
  height: 12px;
  fill: var(--color10);
}
.button.c-default,
button.c-default {
  color: #4F6DEF;
  fill: #4F6DEF;
}
.button.c-warning,
button.c-warning {
  color: #FFA800;
  fill: #FFA800;
}

.sort-item-handle {
  width: 25px;
  height: 25px;
  fill: var(--tabsNavText);
}

#app-login {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
#app-login img.logo {
  width: 100%;
  height: auto;
  margin-bottom: 57px;
}
#app-login .avatar {
  width: 166px;
  height: 166px;
  margin: 0 auto 30px;
  border-radius: 50%;
  border: 20px solid rgba(239, 243, 246, 0.5);
  flex: none;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: scale(1.01);
}
@media screen and (max-width: 767px) {
  #app-login .avatar {
    width: 100px;
    height: 100px;
    border-width: 12px;
  }
}
#app-login .avatar img {
  display: block;
  width: auto;
  height: 100%;
}
#app-login .avatar span {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #4F6DEF;
  width: 100%;
  height: 100%;
  color: #FFFFFF;
  font-size: 60px;
}
@media screen and (max-width: 767px) {
  #app-login .avatar span {
    font-size: 30px;
  }
}
#app-login form {
  width: 100%;
  max-width: 460px;
  min-height: 465px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25), 0px 0px 20px rgba(0, 0, 0, 0.25);
  background: #FFFFFF;
  border: 1px solid #E1E5E8;
  border-radius: 8px;
  padding: 58px 58px 80px;
}
@media screen and (max-width: 767px) {
  #app-login form {
    max-width: 288px;
    padding: 30px 16px 75px;
  }
}
#app-login form input {
  background-color: #EFF3F6;
}
#app-login form button[type=submit] {
  display: block;
  width: 200px;
  margin: 35px auto 0;
}